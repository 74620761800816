<template>
    <div class="condition" v-loading="searchLoading">
        <common-table :list="compareList" :cols="cols" @del="drugDel">
            <template #drug>
                <el-select v-model="drug.model" value-key="comCode" :loading="drug.loading" placeholder="请选择药品" reserve-keyword
                           filterable remote :remote-method="drugSearch" @change="drugChange">
                    <el-option v-for="item in drug.list" :key="item.comCode" :label="item.comName" :value="item"></el-option>
                </el-select>
            </template>
            <!-- 用途 -->
            <template v-slot:slot_purpose="{row}">
                <div v-for="(orr,i) in row.listAll" :key="i" style="margin-right: 10px;display: inline-block;">
                    {{ orr.purposeName }}
                    <!-- <i class="el-icon-close" @click="row.listAll.splice(i,1)" style="color: #5171DA;"  v-if="i!=0 && compareList.length > 1"></i> -->
                </div>
            </template>

            <template v-slot:slot_category="{row}">
                <p v-show="row.real.treatBig">大类：
                    <el-link type="primary" :underline="false" @click="goClassification(row.real,1)">{{row.real.treatBig}}</el-link>
                </p>
                <p v-show="row.real.treatSmall" >亚类：
                    <el-link type="primary" :underline="false" @click="goClassification(row.real,2)">{{row.real.treatSmall}}</el-link>
                </p>
                <p v-show="row.real.treatThree">三类：
                    <el-link type="primary" :underline="false" @click="goClassification(row.real,3)">{{row.real.treatThree}}</el-link>
                </p>
                <p v-show="row.real.treatFour">四类：
                    <el-link type="primary" :underline="false" @click="goClassification(row.real,4)">{{row.real.treatFour}}</el-link>
                </p>
            </template>
            <template v-slot:slot_five="{row}">
                <div style="display: flex;">
                    <div style="width: 50%;padding-right: 5px;color: #748de1;" 
                        v-if="row.real.categoryTopFiveCompetitorList && row.real.categoryTopFiveCompetitorList.length > 0" >
                        <p v-for="item in row.real.categoryTopFiveCompetitorList" :key="item.comCode" @click="clickIcon(item)"
                        style="cursor: pointer;">
                            <i class="el-icon-plus"></i>
                            {{item.comName}}
                        </p>
                    </div>
                    <div style="width: 50%;padding-left: 5px;color: #748de1;" 
                        v-if="row.real.chemistryTopFiveCompetitorList && row.real.chemistryTopFiveCompetitorList.length > 0">
                        <p v-for="item in row.real.chemistryTopFiveCompetitorList" :key="item.comCode" @click="clickIcon(item)"
                        style="cursor: pointer;">
                            <i class="el-icon-plus"></i>
                            {{item.comName}}</p>
                    </div>
                </div>
                
            </template>
            <template v-slot:slot_calcPrice="{row}">
                <div class="flex flex-center">
                    <el-input class="my-input" v-model="row.real.calcPrice" placeholder="请输入"></el-input>
                    ~
                    <el-input class="my-input" v-model="row.real.calcPrice1" placeholder="请输入"></el-input>
                </div>
            </template>
            <template v-slot:slot_calcDay="{row}">
                <div class="flex flex-center">
                    <el-input class="my-input" v-model="row.real.calcDay" placeholder="请输入"></el-input>
                    ~
                    <el-input class="my-input" v-model="row.real.calcDay1" placeholder="请输入"></el-input>
                </div>
            </template>
            <template v-slot:slot_calcTotal="{row}">
                <div class="flex flex-center">
                    <el-input class="my-input" readonly :value="$help.accMul(row.real.calcDay || 0, row.real.calcPrice || 0)"></el-input>
                    ~
                    <el-input class="my-input" readonly :value="$help.accMul(row.real.calcDay1 || 0, row.real.calcPrice1 || 0)"></el-input>
                </div>
            </template>
            <template v-slot:slot_winCorp="{row}">
                <p>中标企业：<el-link type="primary" :underline="false" @click="goBid(row)">{{row.real.winCompNameNumber}}</el-link></p>
                {{row.real.winCompNameList ? row.real.winCompNameList.map(item => item.compName).join('，') : ''}}
            </template>
            <template v-slot:slot_uniformity="{row}">
                <div>
                    <p>申报企业数：<el-link type="primary" :underline="false" @click="goUniformity(row)">{{row.real.declareCompNameNumber || '--'}}</el-link></p>
                    <p>已通过：{{row.real.passNumber || '--' }}</p>
                    <p>未通过：{{row.real.noPassNumber || '--'}}</p>
                    <p>流转中：{{row.real.movingNumber || '--'}}</p>
                </div>
            </template>

            <template v-slot:slot_lastYearSaleAmount="{row}">
                <el-link type="primary" :underline="false" @click="seeYearSale(row)">
                    {{row.real.lastYearSaleAmount || '--'}}{{row.real.lastYearSaleAmount ? $variable.unit : ''}}</el-link>
            </template>
            <template v-slot:slot_lastQuarterSaleAmount="{row}">
                <el-link type="primary" :underline="false" @click="seeQuarterSale(row)">
                    {{row.real.lastQuarterSaleAmount || '--'}}{{row.real.lastQuarterSaleAmount ? $variable.unit : ''}}</el-link>
            </template>

            <template v-slot:slot_lastWinState="{row}">
                <div class="clickable" @click="goCountry(row)" style="color: #748de1;" v-if="row.real.lastWinState">
                    {{row.real.lastWinState || '--'}}
                    <i v-if="['暴涨', '涨价'].includes(row.real.lastWinState)" class="el-icon-top-right up"></i>
                    <i v-else-if="['暴跌', '跌价'].includes(row.real.lastWinState)" class="el-icon-bottom-right down"></i>
                </div>
                <div v-else>--</div>
            </template>

            <template v-slot:slot_component="{row}">
                <!-- <span v-html="row.real.component"></span> -->
                <div style="display: inline-block;" v-for="(item,index) in row.real.component" :key="index">
                    <template v-if="typeof(item) === 'object'">
                        <multi-level-jump :obj="item" :btnList="componentList" :name="'component'"></multi-level-jump>
                    </template>
                    <span v-else>{{item}}</span>
                </div>
            </template>
            <template v-slot:slot_indication="{row}">
                <!-- <span v-html="row.real.indication"></span> -->
                <div style="display: inline-block;" v-for="(item,index) in row.real.indication" :key="index">
                    <template v-if="typeof(item) === 'object'">
                        <multi-level-jump :obj="item" :btnList="indicationList" :name="'indication'"></multi-level-jump>
                    </template>
                    <span v-else>{{item}}</span>
                </div>
            </template>

        </common-table>

        <ts-dialog :show.sync="canvasYear.show" :title="canvasYear.title" close-on-click-modal hide-footer size="50%">
            <div :id="canvasYear.id" class="dialog-canvas"></div>
        </ts-dialog>
    </div>
</template>
<script>
import variable from '@/util/variable'
import CommonTable from './CommonTable'
import searchJs from './searchJs'
export default {
    components: {
        CommonTable,
    },
    mixins: [searchJs],
    props: {
        initDrugs: {
            type: Array,
            default: () => [],
        },
        visitId: {},
    },
    data() {
        return {
            jumpList:false,
            searchLoading:false,
            drug: {
                model: {},
                list: [],
                loading: false,
            },
            purposeList: {
                model: {},
                list: [],
                loading: false,
            },
            compareList: [],
            canvasYear: {
                show: false,
                title: '',
                id: 'compareYear',
                form: {},
            },

            cols: [
                {key: 'purpose', value: '用途',listClass: 'tc',},
                {key: 'base', value: '基本参数', block: true},
                {key: 'drugsNature', value: '药品性质',listClass: 'tc',},
                {key: 'category', value: '药品分类（ATC）'},
                {key: 'five', value: '同类竞品前20名'},
                {key: 'domesticDrugsNumber', value: '批文数（国产）',listClass: 'tc', event: this.goChina},
                {key: 'importedDrugsNumber', value: '批文数（进口）',listClass: 'tc', event: this.goForeign},
                {key: 'compNumber', value: '生产企业数',listClass: 'tc',},
                {key: 'totalDrugsNumber', value: '总批文数',listClass: 'tc',},
                {key: 'materialDrugsNumber', value: '原料药持有企业数',listClass: 'tc',},
                {key: 'base1', value: '药品性价比', block: true},
                {key: 'component', value: '成分'},
                {key: 'indication', value: '适应症'},
                {key: 'offIndication', value: '超适应症'},
                {key: 'pack', value: '包装规格（转化比）'},
                {key: 'usageDosage', value: '用法用量'},
                {key: 'adverseReactions', value: '不良反应'},
                {key: 'drugInteractions', value: '药物相互作用'},
                {key: 'pharmacokinetics', value: '药代动力学'},
                {key: 'mechanismAction', value: '药理作用'},
                {key: 'poison', value: '毒理研究'},
                {key: 'contraindications', value: '禁忌'},
                {key: 'precautions', value: '注意事项'},
                {key: 'minUnitPrice', value: '制剂价格（区间）',listClass: 'tc', calc: true, event: this.goBid},
                {key: 'calcPrice', value: '日用金额【元/天】'},
                {key: 'calcDay', value: '疗程（天）'},
                {key: 'calcTotal', value: '疗程金额<br/>（元/疗程）'},
                {key: 'base2', value: '药品市场信息', block: true},
                {key: 'healthInsurance', value: '医保',listClass: 'tc', event: this.goHealth},
                {key: 'essentialDrugs', value: '基药',listClass: 'tc', event: this.goBase},
                {key: 'essentialDrugsSpec', value: '基药规格'},
                {key: 'protectedVarieties', value: '中药保护品种',listClass: 'tc',},

                {key: 'base5', value: '药品中标信息', block: true},
                {key: 'winProvinceNumber', value: '中标省份数',listClass: 'tc', event: this.goBid},
                {key: 'lastWinTime', value: '最新中标时间',listClass: 'tc',},
                {key: 'lastWinPrice', value: '最新中标价格（元）',listClass: 'tc',event: this.goBid},
                {key: 'maxWinPrice', value: '最高制剂价（元）',listClass: 'tc',},
                {key: 'maxWinProvince', value: '最高价省份',listClass: 'tc', event: this.goBid},
                {key: 'maxWinCompName', value: '最高价企业', event: this.goBid},
                {key: 'minWinPrice', value: '最低制剂价（元）',listClass: 'tc',},
                {key: 'minWinProvince', value: '最低价省份',listClass: 'tc', event: this.goBid},
                {key: 'minWinCompName', value: '最低价企业', event: this.goBid},
                {key: 'avgWinPrice', value: '平均制剂价（元）',listClass: 'tc',},
                {key: 'lastWinState', value: '最新中标状态',listClass: 'tc',},
                {key: 'base6', value: '国家集采信息', block: true},
                {key: 'nationalCentralized', value: '国家集采',listClass: 'tc', event: this.goNation},
                {key: 'winCorp', value: '中标企业'},
                {key: 'purchaseBatch', value: '中选批次'},
                {key: 'packingSpecification', value: '中选规格'},
                {key: 'base3', value: '药品研发', block: true},
                {key: 'developmentApplication', value: '研发申报',listClass: 'tc', event: this.goDev},
                {key: 'uniformity', value: '一致性评价申请'},
                {key: 'clinicalTrialNumber', value: '药物临床试验',listClass: 'tc', event: this.goClinical},
                {key: 'base4', value: '药品市场销售', block: true},
                {key: 'lastYearSaleAmount', value: `最近一年销售额<br/>(${variable.lastYear.value})`,listClass: 'tc', suffix: variable.unit},
                {key: 'lastQuarterSaleAmount', value: `最近一年季度<br/>销售额(${variable.lastQuarter.value})`,listClass: 'tc', suffix: variable.unit},
                {key: 'saleCompNameNumber', value: '在销企业数',listClass: 'tc',},
                {key: 'base7', value: '药品参考文献', block: true},
                {key: 'articlePathNumber', value: '临床路径', event: this.goPath},
                {key: 'articleLiteratureNumber', value: '临床文献数',listClass: 'tc', event: this.goLiterature},
                {key: 'articleGuideNumber', value: '临床指南数',listClass: 'tc', event: this.goGuide},
                {key: 'articleNewsNumber', value: '产品新闻数',listClass: 'tc', event: this.goNews},
                {key: 'drugDisqualificationsNumber', value: '药品质量不合格<br/>公告数',listClass: 'tc', event: this.goDisqualifications},
                {key: 'attentionNumber', value: '关注数量',listClass: 'tc',},
                {key: 'drugReportNumber', value: '药品报告数',listClass: 'tc',},
                {key: 'appraiseNumber', value: '药品评论数',listClass: 'tc',event: this.goAppraise},
            ],
        }
    },
    created() {
        const list = localStorage.getItem("all_contrastList") ? JSON.parse(localStorage.getItem("all_contrastList")) : []
        if(list && list.length!=0){
            localStorage.removeItem("all_contrastList");
            this.jumpList = true
            this.searchLoading = true
            list.forEach((item,index)=>{
                this.$set(item, 'real', {})
                this.$api.get('con/drugContrast/queryDrugContrast', {
                    comCode: item.comCode
                }, {'Visit-Id': this.$help.getVisitId(this.visitId)})
                .then(res => {
                    if (res.data) {
                        res.data.totalDrugsNumber = res.data.domesticDrugsNumber + res.data.importedDrugsNumber
                        res.data = this.setLink(res.data)
                        this.$set(item,'real',res.data)
                    }
                })
                .finally(()=>{
                    if(index === list.length-1) this.searchLoading = false
                })
                item.compName = ''
                item.spec = ''
                this.purposeChange(item.comCode,true,index)
                this.compareList.push(item)
                this.finalChange()
            })
        }
        this.drug.list = this.initDrugs
        
    },
    computed: {
        compareMap() {
            const map = {}
            this.compareList.forEach(item => {
                map[item.comCode] = true
            })

            return map
        },
    },
    methods: {
        clickIcon(row){
            this.drug.model = row
            this.drugChange()
        },
        //pdf
        openPDF() {
            if (this.compareList.length > 0) {
                this.$help.exportInfo('确定导出吗', 'con/drugContrast/exportDrugContrast', this.compareList.map(item => {
                    const calcPrice = item.real.calcPrice ? (isNaN(parseFloat(item.real.calcPrice)) ? 0 : parseFloat(item.real.calcPrice)) : 0
                    const calcPrice1 = item.real.calcPrice1 ? (isNaN(parseFloat(item.real.calcPrice1)) ? 0 : parseFloat(item.real.calcPrice1)) : 0
                    const calcDay = item.real.calcDay ? (isNaN(parseFloat(item.real.calcDay)) ? 0 : parseFloat(item.real.calcDay)) : 0
                    const calcDay1 = item.real.calcDay1 ? (isNaN(parseFloat(item.real.calcDay1)) ? 0 : parseFloat(item.real.calcDay1)) : 0
                    return {
                        comCode: item.comCode,
                        dayPrice: `${calcPrice || '--'}~${calcPrice1 || '--'}`,
                        dayNumber: `${calcDay || '--'}~${calcDay1 || '--'}`,
                        dayAmount: `${this.$help.accMul(calcPrice, calcDay)}~${this.$help.accMul(calcPrice1, calcDay1)}`,
                    }
                }))
            } else {
                this.$store.commit('error', '请选择药品')
            }
        }, 
        drugSearch(keyword = '') {
            let param = {}
            param.keyword = keyword
            // if(!this.jumpList && this.compareList && this.compareList.length !== 0){
            //     param.purposeCode = ''
            //     let array = this.compareList[0].listAll
            //     for (let index = 0; index < array.length; index++) {
            //         if(index === array.length-1){
            //             param.purposeCode += array[index].purposeCode
            //         }else{
            //             param.purposeCode += array[index].purposeCode + ','
            //         }
            //     }
            // }
            this.drug.loading = true
            this.$api.get('report/drugContrast/queryAimBaseDrugsListByKeyword', param)
                .then(res => {
                    this.drug.list = res.data
                })
                .finally(() => {
                    this.drug.loading = false
                })
        },
        //获取用途
        purposeChange(code="",flag = false,i){
            const _code = code?code: this.drug.model.comCode
            const index = flag ? i : this.compareList.length-1
            this.$api.post(`con/drugContrast/queryDrugPurposeList?comCode=${_code}`)
            .then(res => {
                if (res.data) {
                    this.$set(this.compareList[index],'listAll',res.data)
                } 
            })
            .finally(() => {
                
            })
        },
        drugChange() {
            if (this.compareMap[this.drug.model.comCode]) {
                this.$store.commit('error', '该药品已添加')
            } else {
                const model = this.drug.model
                this.$set(model, 'loading', true)
                this.$set(model, 'real', {})
                this.$api.get('con/drugContrast/queryDrugContrast', {
                    comCode: this.drug.model.comCode
                }, {'Visit-Id': this.$help.getVisitId(this.visitId)})
                    .then(res => {
                        if (res.data) {
                            res.data.totalDrugsNumber = res.data.domesticDrugsNumber + res.data.importedDrugsNumber
                            res.data = this.setLink(res.data)
                            model.real = res.data
                        } else {
                            model.real = {}
                        }
                        if(this.compareList && this.compareList.length === 1){
                            this.drugSearch('',this.compareList)
                        }
                    })
                    .finally(() => {
                        this.$set(model, 'loading', false)
                    })
                this.compareList.push(model)
                if(this.compareList && this.compareList.length === 1){
                    this.drug.list = []
                }
                this.purposeChange()
                this.finalChange()
            }
            this.drug.model = {}
        },
        drugDel(index) {
            this.compareList.splice(index, 1)
            this.finalChange()
        },
        finalChange() {
            this.$emit('change', this.compareList)
        },

        goAppraise(row) {
            this.$store.commit('showComment', {
                type: 0,
                comCode: row.comCode,
                comName: row.comName,
                read: true,
            })
        },
        goNation(data) {
            this.goPage('/store/dr_national_centralized_procurement', data)
        },

        goDev(data) {
            this.goPage('/store/flow_development_review_progress', data)
        },

        // 跳往医保目录
        goHealth(data) {
            this.goPage('/store/du_health_insurance_directories', data)
        },

        // 跳往基药目录
        goBase(data) {
            this.goPage('/store/du_essential_drugs', data)
        },

        // 跳往国产药品
        goChina(data) {
            this.goPage('/store/dr_domestic_drugs', data)
        },

        // 跳往进口药品
        goForeign(data) {
            this.goPage('/store/dr_imported_drugs', data)
        },

        // 跳往招投标
        goBid(data) {
            this.goPage('/store/mainf_drug_winning_informations', data)
        },

        // 跳往临床
        goClinical(data) {
            this.goPage('/store/rd_drug_clinical_trials', data)
        },

        // 跳往一致性评价
        goUniformity(data) {
            this.goPage('/store/flow_development_review_progress_patch-new-1', data)
        },
        // 跳往按类别
        goClassification(data,val) {
            let arr = []
            if(val === 1){
                arr.push({
                    treatCategoryCode: data.treatBigCode,
                    treatCategoryName: data.treatBig,
                    id: data.treatBigId
                })
            }else if(val === 2){
                arr.push({
                    treatCategoryCode: data.treatBigCode,
                    treatCategoryName: data.treatBig,
                    id: data.treatBigId
                },{
                    treatCategoryCode: data.treatSmallCode,
                    treatCategoryName: data.treatSmall,
                    id: data.treatSmallId
                })
            }else if(val === 3){
                arr.push({
                    treatCategoryCode: data.treatBigCode,
                    treatCategoryName: data.treatBig,
                    id: data.treatBigId
                },{
                    treatCategoryCode: data.treatSmallCode,
                    treatCategoryName: data.treatSmall,
                    id: data.treatSmallId
                },{
                    treatCategoryCode: data.treatThreeCode,
                    treatCategoryName: data.treatThree,
                    id: data.treatThreeId
                })
            }else{
                arr.push({
                    treatCategoryCode: data.treatBigCode,
                    treatCategoryName: data.treatBig,
                    id: data.treatBigId
                },{
                    treatCategoryCode: data.treatSmallCode,
                    treatCategoryName: data.treatSmall,
                    id: data.treatSmallId
                },{
                    treatCategoryCode: data.treatThreeCode,
                    treatCategoryName: data.treatThree,
                    id: data.treatThreeId
                },{
                    treatCategoryCode: data.treatFourCode,
                    treatCategoryName: data.treatFour,
                    id: data.treatFourId
                })
            }
            const { href } = this.$router.resolve({
                path: '/new/aim_drugs_treat_big',
                query: {
                    odd:JSON.stringify(arr)
                }
            });
            window.open(href, "_blank");
        },


        // 跳转原料药
        goMaterial(data) {
            this.goPage('/store/dr_material_drugs', data)
        },
         //跳转全国药品价格
        goCountry(data) {
            this.goPage('/bidding-info-country', data)
        },

        // 跳转临床路径
        goPath(data) {
            this.goAnotherPage('/store/base_article_path', data)
        },
        goLiterature(data) {
            this.goAnotherPage('/store/base_article_literature', data)

        },
        goGuide(data) {
            this.goAnotherPage('/store/base_article_guide', data)
        },
        goNews(data) {
            this.goAnotherPage('/store/base_article_news', data)
        },
        goDisqualifications(data) {
            this.goPage('/store/pi_drug_disqualifications', data)
        },

        goPage(url, data) {
            const { href } = this.$router.resolve({
                path: url,
                query: {
                    comCode: data.comCode,
                }
            });
            window.open(href, "_blank");
        },
        goAnotherPage(url, data) {
            const { href } = this.$router.resolve({
                path: url,
                query: {
                    products: `%${data.comName}%`,
                }
            });
            window.open(href, "_blank");
        },

        seeYearSale(data) {
            this.canvasYear.show = true
            this.canvasYear.form = data
            this.canvasYear.title = `${data.comName}历年销售趋势`

            this.$nextTick(() => {
                this.$help.renderBarOnly(this.canvasYear.id, Object.keys(data.real.saleTrend).map(key => {
                    return {
                        name: key,
                        value: data.real.saleTrend[key],
                    }
                }))
            })
        },
        seeQuarterSale(data) {
            this.canvasYear.show = true
            this.canvasYear.form = data
            this.canvasYear.title = '最新一年季度销售趋势'

            this.$nextTick(() => {
                this.$help.renderBarOnly(this.canvasYear.id, Object.keys(data.real.lastYearSaleTrend).map(key => {
                    const arr = key.split('q')
                    return {
                        name: `${arr[0]}年${arr[1]}季度`,
                        value: data.real.lastYearSaleTrend[key],
                    }
                }))
            })
        }
    }
}
</script>